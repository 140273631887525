@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-height: 100vh;
  color: #e1e1e1;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik', sans-serif;
}
.container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.container:focus {
  outline: none;
}

.container .valid-keys {
  font-size: 10rem;
  font-weight: bold;
  min-width: 600px;
  border-bottom: solid 2px #fff;
}
.container .valid-keys .matched {
  background-color: #fdf8c5;
  color: #282c34;
}

.container .valid-keys .matched.completed {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  animation: moveDown 200ms ease-in;
}

.container .valid-keys .remainder {
  opacity: 0.5;
}

.container .completed-words {
  width: max-content;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  text-align: left;
  padding: 20px 40px 20px 80px;
  font-size: 1.8rem;
  line-height: 2.5rem;
  opacity: 0.5;
  display: flex;
}

.container .completed-words ol {
  flex-grow: 1;
  align-self: flex-end;
}

.container .typed-keys {
  margin-top: 20px;
  font-size: 1.3rem;
  width: 600px;
  overflow: hidden;
}

.container .timer {
  font-size: 2.3rem;
  color: #fdf8c5;
}

.container .ended {
  width: 600px;
  padding: 60px;
  margin: 20px 0;
  border: dashed 3px #fdf8c5;
  position: absolute;
  background-color: black;
}
.container .ended h2 {
  color: #fdf8c5;
  font-size: 3rem;
}
.container .ended p {
  font-size: 1.5rem;
}

.container .ended button {
  background-color: #5d5d5e;
  color: #e1e1e1;
  border: none;
  margin-top: 10px;
  padding: 20px 60px;
  cursor: pointer;
}

@keyframes moveDown {
  0% {
    top: 0;
    opacity: 1;
  }
  20% {
    top: -3rem;
    background-color: #26947c;
  }

  100% {
    top: 22rem;
    opacity: 0;
  }
}
